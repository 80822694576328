@media print {
	.sf-menu-container {
		display: none !important;
	}
	.header{
		display: none !important;

	}
	.footer-container{
		display: none !important;

	}
}
