@charset 'utf-8';
@import "font-awesome";
@import "foundations";
// settings
$text-green: #088725;
$text-breadcrumb: #777777;
$link-blue: #1e486e;
$selected-red: #940000;
$gov-red: #CB1F2B;
// from original global
input[type=search] {
	width: rem-calc(155);
	box-sizing: border-box;
	border: 0;
	border-bottom: 2px solid #ccc;
	border-radius: 0;
	font-size: 14px;
	background-color: white;
	padding: 0px 0px 0px 10px;
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
	height: 1.6rem; // box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}

@media screen and (max-width: 46.250em) {
input[type=search] {
	width: 2.6875rem;
	box-sizing: border-box;
	border: 0;
	border-bottom: 2px solid #ccc;
	border-radius: 0;
	font-size: 14px;
	background-color: white;
	padding: 0px 0px 0px 10px;
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
	height: 1.6rem; // box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}
}


@media screen and (max-width: 39.99em) {
input[type=search] {
	width: rem-calc(155);
	box-sizing: border-box;
	border: 0;
	border-bottom: 2px solid #ccc;
	border-radius: 0;
	font-size: 14px;
	background-color: white;
	padding: 0px 0px 0px 10px;
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
	height: 1.6rem; // box-shadow: inset 0 1px 2px rgba(0,0,0,.39), 0 -1px 1px #FFF, 0 1px 0 #FFF;
}
}

.sf-menu li:hover {
	// visibility:		inherit; /* fixes IE7 'sticky bug' */
}

//////////////
// global
//////////////
body {
	font-family: "Microsoft JhengHei", Helvetica, "Myriad Pro", Arial, sans-serif;
	p,
	li,
	.p {
		font-size: 100% !important;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	.p {
		font-family: inherit;
	}
}

a {
	color: $text-green;
}

// layout control, unaffect from font-changer
.feature-bar.top-bar{
	li {
		font-size: 1rem !important;
	}
}

// breadcrumb
.breadcrumb-container {
	padding-left: 1rem;
	padding-top: 1rem;
}

.breadcrumbs {
	color: $text-breadcrumb;
	li {
		text-transform: none;
	}
	a {
		color: inherit;
	}
}

.breadcrumbs li:not(:last-child)::after {
	content: ">";
}

// avoid FOUC
.sf-menu-container .submenu.vertical {
	display: none;
}

// end of global
// built for foundation
/////////////////////
// mobile first
/////////////////////
.hk-logo {
	padding: 1.5rem;
}

.title-bar {
	&.mobile {
		background: none;
		.menu-icon {
			$margin-right: rem-calc(30) + 2rem;
			margin-right: -$margin-right;
			margin-left: 2.25rem;
			&:after {
				background: $text-green;
				-webkit-box-shadow: 0 7px 0 $text-green, 0 14px 0 $text-green;
				box-shadow: 0 7px 0 $text-green, 0 14px 0 $text-green;
			}
		}
		h1 {
			color: black;
		}
		.mobile-logo-container {
			text-align: center;
			padding: 1.875rem 0;
			margin: auto;
			h1 {
				font-weight: bold;
			}
			a {
				display: block;
			}
		}
	}
}

@media print {
	a[href]:after,#print {
		content: none !important;
	}
	div.feature-bar.top-bar,#print img,div.social-bar.top-bar.hide-for-small-only{
		display: none
	}
}

// I&T menu
.hl-menu-container {
	padding: 1rem;
}

.highlight-menu {
	font-family: helvetica, arial, sans-serif;
	&.mobile {
		$emerald-green: #048622;
		background: $emerald-green;
		color: white;
		text-align: center;
		text-transform: uppercase;
		a {
			color: inherit;
		}
		ul {
			background: #FCFFE9; // light-green
			color: $emerald-green;
			li {
				border-bottom: 1px solid #D5D7CC; // grey
				&:last-of-type {
					border: none;
				}
			}
		}
		&.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
			border-color: white transparent;
		}
		&.accordion-menu .nested.is-accordion-submenu {
			margin: 0;
		}
	}
}

// menus
.lang-menu {
	color: $text-green;
	height: 20.5px; // make it stick at the bottom of container
	a {
		padding: 0;
		color: inherit;
	}
	li:last-of-type {
		img {
			vertical-align: top;
		}
	}
}

@media screen and (max-width: 39.9375em) {
	.lang-menu {
		height: auto;
		a {
			padding: 0.7rem 1rem;
		}
	}
	.title-bar.mobile {
		padding: 0;
		.mobile-logo-container {
			padding: 0;
		}
	}
}

.search-menu {
	// display: flex;
	//    align-items: flex-end;
	a {
		padding: 0;
	}
	label {
		display: inline-block;
	}
	.glass {
		margin-left: -1.2em;
		margin-top: -2px;
		width: 48px;
	}
}


// try to be mobile first, rather than override by desktop first
@media screen and (min-width: 40em) {
	.search-menu {
		display: flex;
		align-items: flex-end;
		img {
			vertical-align: bottom;
		}
		label {
			span {
				line-height: 1.5;
				text-transform: uppercase;
			}
			span,
			input {
				vertical-align: bottom;
			}
		}
	}
}

@media screen and (max-width: 39.9375em) {
	.search-menu {
		height: 2.4rem;
		label {
			margin-left: 2rem;
		}
		input {
			box-shadow: inset 0 1px 2px rgba(0, 0, 0, .39), 0 0px 1px #eee, 0 0px 0 #FFF;
		}
	}
}

@import 'components/font-changer';

.header {
	background: url('../images/header/bkgdtexture.gif') repeat;
	.top-bar {
		background: none;
		padding-left: 0;
		padding-right: 0;
		&.logo-bar {
			padding-bottom: 0;
		}
		&.feature-bar {
			padding: 0;
			flex-wrap: wrap;
			align-items: flex-end;

			.top-bar-left {
				&:nth-child(1) {
					flex: 0 1 auto;
				}

				&:nth-child(2) {
					flex: 1 0 auto;
				}
			}
		}
		ul {
			background: none;
		}
	}
	.font-changer {
		// padding-left: 1rem;
		position: inherit;
	}
}

@media screen and (min-width: 40em) {
	.top-bar {
		&.feature-bar {
			border-bottom: 1px solid $gov-red;
		}
	}
	.font-changer {
		line-height: 1;
	}
}

@media screen and (max-width: 39.9375em) {
	.header {
		background: none;
		.top-bar {
			.search-menu {
				float: none !important;
				background: url('../images/header/yellow_bar_repeat.png') repeat-x;
			}
		}
		.feature-bar {
			&.top-bar {
				.top-bar-left {
					// -webkit-box-flex: 0;
					// -webkit-flex: 1 1 100%;
					// -ms-flex: 1 1 100%;
					// flex: 1 1 auto;
					max-width: 100%;
				}
				.top-bar-right {
					// -webkit-box-flex: 0;
					// -webkit-flex: 0 0 100%;
					// -ms-flex: 0 1 100%;
					// flex: 1 1 auto;
					// max-width: 100%;
					&.search-bar {
						// flex: 0 0 100%;
					}
				}
				.font-changer {
					float: right; // padding-right: 1rem;
					margin-bottom: 0.5rem;
				}
			}
		}
	}
}

.social-bar {
	height: rem-calc(25);
	background: url(../images/header/weathericon_bg.png) left repeat-y;
	font-size: 13px; // border-bottom: 1px solid #549E30;
	&.top-bar {
		ul {
			background: none;
		}
	}
	a {
		color: black;
	}
	.social-menu {
		img {
			vertical-align: top;
		}
		span {
			line-height: rem-calc(16);
			display: inline-block;
		}
	}
}

li#share {
	&::after {
		display: none;
	}
	&:hover,&:focus {
		color: black;
		& ul.share-bag, ul.share-bag:hover {
			width: 150px;
			height: 100px;
			min-width: 100px;
			left: 50px;
			z-index: 500;
			background: url("../images/common/bg_share.png") no-repeat;
			li{
				display: inline
			}
		}
	}
	&:focus {
		color: black;
	}
	a {
		color: black;
	}
}

ul.share-bag {
	width: 0px;
	height: 0px;
	min-width: 0px;
	position: absolute;
	top: 25px;
	// margin-right: -150px;
	// display: none;
	// padding: 0px 0px;
	// &:hover,&:focus{
	// 	background: url("../images/common/bg_share.png") no-repeat;
	// 	width: 150px;
	// 	height: 100px;
	// 	min-width: 100px;
	// 	li{
	// 		display: inline
	// 	}
	// }
	li {
		width: 20px;
		height: 20px;
		// top: 80px;
		margin: 30px 5px 0px;
		display: none;
		a {
			// padding: 0px 0px;
			display: inline;
		}
	}
}



.sf-menu-container {
	padding-left: 0;
	padding-right: 0;
	.menu_li_lv1 {
		ul {
			
				a {
					color: blue;
				}
			}
		}
	}
	.sf-menu_en {
		// background: #11881D;
		margin: 0;
		padding: 0;
		list-style: none;
		background: #EFEFF1;
		.is-active {
			&>a {
				background: none;
			}
		}
		a {
			color: white;
			
			display: block; // color: inherit;
		}
		li{
			// width: 100%;
			// float:			left;
			// position:		relative;
			border-bottom: 3px solid #A4D100;
			
		}
		li:nth-last-child(9) {
			font-size:12.4px !important;
			}
		&>li {
			background: url('../images/menu/menu_bg_lv1.png') repeat-y right;
			background-color: #11881D;
			&:hover,
			&.is-active {
				color: #1e486e;
				background: url('../images/menu/menu_bg_lv1_rollover.png') repeat-y right;
				background-color: #E8E8E8;
				&>a {
					color: inherit;
					border-right: 2px solid #1e486e; // border-right: 4px solid black;
				}
			} // width: 152px;
			// line-height:1.4em;
			// height:auto;
			// min-height:28px;
			a {
				width: 100%; // line-height: 1rem;
				// margin: 10px 0;
				// white-space: normal;
				// padding-left:13px;
				//    padding-top:4px;
				//    padding-bottom:12px;
				//    padding-right:12px;
			}
		}
		ul {
			background-color: #0e450e;
			li {
				border-bottom: 2px solid #518052;
				&:last-of-type {
					border-bottom: none;
				}
				a {
					span {
						color: white; // padding:auto inherit;
					}
				}
			}
		}
	}


	.sf-menu {
		// background: #11881D;
		margin: 0;
		padding: 0;
		list-style: none;
		background: #EFEFF1;
		.is-active {
			&>a {
				background: none;
			}
		}
		a {
			color: white;
			
			display: block; // color: inherit;
		}
		li{
			// width: 100%;
			// float:			left;
			// position:		relative;
			border-bottom: 3px solid #A4D100;
			
		}
		
		&>li {
			background: url('../images/menu/menu_bg_lv1.png') repeat-y right;
			background-color: #11881D;
			&:hover,
			&.is-active {
				color: #1e486e;
				background: url('../images/menu/menu_bg_lv1_rollover.png') repeat-y right;
				background-color: #E8E8E8;
				&>a {
					color: inherit;
					border-right: 2px solid #1e486e; // border-right: 4px solid black;
				}
			} // width: 152px;
			// line-height:1.4em;
			// height:auto;
			// min-height:28px;
			a {
				width: 100%; // line-height: 1rem;
				// margin: 10px 0;
				// white-space: normal;
				// padding-left:13px;
				//    padding-top:4px;
				//    padding-bottom:12px;
				//    padding-right:12px;
			}
		}
		ul {
			background-color: #0e450e;
			li {
				border-bottom: 2px solid #518052;
				&:last-of-type {
					border-bottom: none;
				}
				a {
					span {
						color: white; // padding:auto inherit;
					}
				}
			}
		}
	}
	.sf-vertical {
		li {
			// position: relative;
		}
		&>li:hover {
			ul {
				// right: -190px;
				// top: 0;
				// left: auto;
				// z-index:		99;
			}
		}
		&.top {
			// margin-bottom: 0;
			a {
				// padding-left:13px;
				//    padding-top:4px;
				//    padding-bottom:12px;
				//    padding-right:12px;
				line-height: 1em; // to fullfil font changer, follow original site
				white-space: normal;
			}
			.submenu-toggle {
				height: 38px; // since it is 40px by foundation default
				width: 25px; //Default is too wide
			}
		}
		&.bottom {
			padding: 15px;
			background: #EFEFF1;
			font-size: rem-calc(14);
			h3 {
				font-size: rem-calc(14);
				margin-top: 1rem;
			}
			img {
				max-width: 100%;
				width: 100%;
			}
			li {
				background: none;
				border: none;
				color: black;
				a {
					color: $text-green;
					display: block;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			p {
				font-size: inherit;
			}
		}
	}
	.accordion_submenu .submenu-toggle[aria-expanded='true'] {}
	.accordion_submenu .submenu-toggle[aria-expanded='true']::after {
		-webkit-transform: scaleY(1);
		-ms-transform: scaleY(1);
		transform: scaleY(1);
	}
	.accordion_submenu .submenu-toggle::after {
		-webkit-transform: rotateZ(-90deg);
		-ms-transform: rotateZ(-90deg);
		transform: rotateZ(-90deg);
	}
	.sub-menu {
		background-color: #0e450e;
	} // the triangle when data-submenu-toggle="true"
	.submenu-toggle::after {
		border-color: white transparent transparent;
	} // the triangle when data-submenu-toggle="false"
	.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
		border-color: white transparent transparent;
	}


// horizontal bar
hr {
	&.separator {
		border: none;
		background: url('../images/shadow_bar.png') repeat-x left;
		height: 10px;
		margin-bottom: 0.1rem;
	}
}

.group1.grid-padding-x {
	// .group2.grid-padding-x {
	&>.cell {
		padding: 0.3125rem 0.3125rem;
	}
}

.banner-container {
	.grid-x {
		align-items: center;
	}
	.grid-padding-x>.cell {
		// padding: 0.3125rem 0.3125rem;
	}
	ul {
		li {
			list-style-type: none;
		}
	}
	img {
		width: 100%;
	}
	.group1 {
		margin-bottom: 0; //Increased padding to match original site
		padding: 0 1rem;
		width: 100%;
	}
	.group2 {
		padding: 0 1rem;
		&.grid-x>.medium-1 {
			// $width: 100%/9;
			// width: $width;
		}
	}
}

@media screen and (min-width: 40em) {
	.banner-container {
		.group2 {
			.grid-padding-x>.cell {
				padding: 0.3125rem 0;
			}
		}
	}
}

@media screen and (max-width: 39.9375em) {
	.banner-container {
		padding: 0 2rem;
		.group1 {
			margin-bottom: 0;
			padding: 0;
			li {
				/* Hidden due to layout problems with extra banner
		    	&:nth-of-type(1) {
		    		padding-left: 5rem;
		    	}

		    	&:nth-of-type(2) {
		    		padding-right: 5rem;
		    	}

		    	&:nth-of-type(3) {
		    		padding: 0 5rem;
		    	}
			*/
				&:last-of-type {
					padding: 0 2rem;
				}
			}
		}
		.group2s {
			padding: 0 1rem;
			&.grid-x>.medium-1 {
				width: 33.33333%;
			}
		}
	}
}

.footer,
.content-margin {
	margin-left: 1.25rem; // same as ul since there are lots of ul used before
}

.footer {
	margin-top: 1rem;
	a {
		color: #1e486e;
		&:hover {
			text-decoration: underline;
		}
	}
}

.footer-left-nav,
.footer-right-nav {
	// flex: 0 0 auto;
}

.footer-left-nav {
	float: left; // -webkit-box-flex: 0;
	// -webkit-flex: 0 0 auto;
	// -ms-flex: 0 0 auto;
	// flex: 0 0 auto;
	// padding-right: 0.625rem;
	// padding-left: 0.625rem;
	// follow a
	a,
	span {
		border-right: 1px solid $link-blue;
		padding: 3px 0.2rem 2px; // padding: .7rem 1rem;
		display: block;
		line-height: 1;
	}
	ul {
		li:first-child,
		li:last-child {
			a,
			span {
				border: none;
			}
		}
	}
}

.footer-right-nav {
	float: right; // -webkit-box-flex: 0;
	// -webkit-flex: 0 0 auto;
	// -ms-flex: 0 0 auto;
	// flex: 0 0 auto;
	// padding-right: 0.625rem;
}

@media screen and (max-width: 39.9375em) {
	.footer-left-nav,
	.footer-right-nav {
		float: none;
	}
	.footer-left-nav {
		ul {
			li:first-child {
				a,
				span {
					padding-left: 0;
				}
			}
		}
	}
}

///////////////
// my blog
///////////////
.year-selector-wrapper {
	label {
		display: inline-block;
	}
	select {
		display: inline-block;
		font-size: rem-calc(14);
		width: rem-calc(100);
		height: rem-calc(20);
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0.35rem;
	}
}

.content-wrapper {
	margin: 0 1rem;
	line-height:2.5rem;

}

.news-content {
	table {
		tbody {
			tr {
				border-color: transparent;
				&:nth-child(even) {
					background-color: #ecf2f6;
				}
				th {
					color: white;
					text-align: left;
				}
				td {
					&:last-of-type {
						white-space: nowrap;
					}
				}
			}
		}
	}
}

.blog-content {
	h4 {
		font-size: rem-calc(15);
	}
	.thumbnail {
		border: 0;
		box-shadow: none;
		text-align: center;
	}
	a.details {
		display: block;
		text-align: right;
	}
	img {
		max-width: rem-calc(214);
	}
	.mainTitle {
		font-size: 1.1em;
		font-weight: bold;
	}
	.bg_img {
		float: right;
		margin: 0 0 0 10px;
	}
	.bg_img_2 {
		float: left;
		margin: 0 10px 0 0;
	}
	p {
		font-size: 0.8125em;
		text-align: justify;
		line-height: 25px;
	}
	.no-thumbnail {
		@include breakpoint(medium) {
			margin-right: 13.375rem;
		}
		@include breakpoint(small only) {
			margin-right: none;
		}
		&.media-object-section:last-child:not(:nth-child(2)) {
			padding-left: 0;
		}
	}
	.media-object {
		justify-content: space-between;
	}
}

.about-content {
	.mainTitle {
		font-weight: bold;
		margin-bottom: 1rem;
	}
}

.bio-content {
	p {
		text-align: justify;
	}
}

.fbl-content {
	p {}
	.nav-button {
		margin-bottom: 1.5rem;
		width:100%;
		.button {
			white-space: normal !important;
			word-wrap: break-word;
			width: 100%;
			margin: 0.0625em;
			padding: 1.875rem 4.125rem;
			border-radius: 0.3125rem;
			background-color: #00abbd;
			cursor: default;
			font-size: 1em;
			position: static;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			width:250px;
			a {
				color: white;
				&:hover {
					text-decoration: underline;
				}
				p {
					width: 100%;
				}
			}
			* {
				vertical-align: center;
			}
		}
	}
	.fbl-section-title {
		color: #00abbd;
		margin-bottom: 1.5rem;
	}
	.fbl-subsection-title {
		color: #b48879;
		margin-bottom: 1.5rem;
	}
	.fbl-faq-list {
		list-style-image: url('../../assets/images/ul_1.png');
		ul {
			list-style-image: url('../../assets/images/ul_2.png');
			margin-bottom: 1.5rem;
		}
	}
	.fbl-index-list {
		list-style-image: url('../../assets/images/ul_3.png');
	}
	.fbl-refer {
		border: 3px solid #00abbd;
		width: 100%;
		text-align: center;
	}
	ul.no-margin-list {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.aboutus-list {
	width: 400px;
	border: 1px solid gray;
	margin-bottom: 100px;
	ul {
		padding: 20px 0 0 20px;
		list-style: none;
		list-style-image: url('../../assets/images/menu/arrow.png');
		li {
			text-indent: 0em;
			padding-left: 20px;
			border-bottom: 2px solid #ebebeb;
		}
	}
	p {
		padding-left: 3px;
		color: #ffffff;
		background: url('../../assets/images/whats_new/header-bg-blue.png');
	}
}

@media screen and (max-width: 39.9375em) {
	.media-object {
		display: block;
		.media-object-section {
			img {
				width: auto;
				max-width: 70%;
			}
		}
	}
}

.tender-content {
	margin-bottom: 20rem;
}

///////////////
// home
///////////////
// this is pure foundation version for I&T
%highlight-heading {
	//$border-color: #407309;  deep green
	$border-color: #048622; //lighter green in log
	// height: 50px;
	// padding: 3% 3% 3% 3%;
	border-left: 5px solid;
	border-color: $border-color;
	font-size: inherit;
	margin-bottom: 0;
	padding-left: 1rem;
	padding-bottom: 1rem;
}

.highlight2 {
	$text-bg: #E7E7E7;

	li {
		list-style: none;
		padding: 0.25rem;
		margin-bottom: 1rem;
		.wrapper {
			height: 100%;
			background: $text-bg;
		}
	}
	a {
		color: #397731; // blue-green
		// color: #408291; // blue-green
	}
	p {
		padding: 0.7rem; // background: $text-bg;
		// height: rem-calc(113);
		overflow: auto;
		margin-bottom: 0; // text-align: justify;
	}
	.heading {
		@extend %highlight-heading;
		background: white; // min-height: rem-calc(64);
	}
}

// this is modified version of original layout logic
.highlight {
	margin-bottom: 1.5rem;
	padding: 0;
	list-style-type: none;
	color: $text-green;
	display: table;
	table-layout: fixed;
	width: 100%;
	img {
		max-width: 100%;
	}
	li {
		display: table-cell;
	}
	a {
		color: inherit;
	}
	.heading {
		@extend %highlight-heading;
	}
}

@media screen and (max-width: 39.9375em) {
	.highlight2 {
		margin: 0;
		padding: 0 1rem;
		img {
			width: 100%;
		}
		p {
			height: auto;
			overflow: auto; // text-align: justify;
		}
	}
	.highlight {
		display: block;
		width: 100%;
		li {
			display: block;
			padding-bottom: 1rem;
			&:last-of-type {
				padding-bottom: 0;
			}
			p {
				height: 50px;
				padding: 3% 3% 3% 3%;
				border-left: 5px solid;
				border-color: #5e9312;
				font-size: 1.15em;
				color: #5e9312;
				vertical-align: middle;
			}
			img {
				width: 100%;
			}
		}
	}
}

.bxslider {
	&#home-banner-slider {
		margin: 0;
	}
}

.orbit {
	&.home-slider {
		margin-bottom: 1.5rem;
	}
}

.orbit-container {
	.item {
		img {
			width: 100%;
		}
	}
	ul {
		li {
			// list-style-type: none;
		}
	}
	p {
		font-size: 16px;
		background-color: #163963;
		color: white;
		text-align: center;
	}
}

.orbit-controls {
	button {
		&:focus {
			background: none;
		}
	}
}

@media screen and (max-width: 39.9375em) {
	.carousel-inner,
	.highlight {
		margin: 0;
		padding: 1rem;
	}
}

.additional-info {
	padding: 1rem;
	img {
		width: 100%;
	}
	a {
		display: block;
	}
	h3 {
		padding-top: 1.5rem;
	}
	.find-in-fb {
		text-align: center;
		img {
			width: auto;
		}
	}
}

@media print,
screen and (min-width: 40em) {
	// offcanvas
	.js-off-canvas-overlay.is-overlay-fixed {
		position: initial;
	}
	.off-canvas-content.is-open-left.has-transition-push {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
	.off-canvas.is-open {
		display: none;
	}
}

// end of home
//////////////
// helpers 
//////////////
.text-capitalize {
	text-transform: capitalize;
}

.text-upper {
	text-transform: uppercase;
}

.img-responsive {
	width: 100%;
}

// end of helpers

/* For logo alignment */

.logo-container {
	padding-left: 1rem;
	padding-top: 1rem;
	align-items: center;
}


/* Test class for testing menu bar */

.as-table {
	display: table;
	table-layout: fixed;
}

.as-cell {
	display: table-cell;
}

.as-col {
	display: table-column;
}

.icon_pdf {
	background-image: url(../images/pdf_icon.png);
	background-position: right;
	padding-right: 20px !important;
	width: auto;
	height: auto;
	background-repeat: no-repeat;
	text-decoration: none;
}


/* Color for I&T Support */

.highlight2 li {
	h2,
	p {
		color: #048622;
	}
}

.menu_lv1 span {
	display: block;
	/*max-width: 80%;*/
	max-width: 10em;
}


/* Dirty fix for span length; consider replacing with something else later? */

span.hundred-percent-length {
	max-width: 100%;
}

a.menu_lv1 {
	padding-left: 0.7rem;
}


/* Generic style for bold text with no special meanings */

.bolded-text {
	font-weight: bold;
}

.italic-text {
	font-style: italic;
}


/* Substitute for mainHr in old site */

.main-hr {
	background: url(../images/shadow_bar.png) transparent repeat-x;
	color: #616161;
	display: flex;
	align-items: center;
	margin-top: 1em;
	margin-bottom: 1em;
	font-size: 1.2rem;
	padding-left: 0.5em;
}


/* General class for a bit of spacing between next element and this class */

.bottom-margin {
	margin-bottom: 2rem;
	@at-root p#{&} {
		margin-bottom: 2.5rem;
		text-align: center;
	}
	&.more {
		margin-bottom: 3rem;
	}
	&.double {
		margin-bottom: 4rem;
	}
}

.top-margin {
	margin-top: 2rem;
	&.more {
		margin-top: 3rem;
	}
}


/* Container for image thumbnails in press releases, also serves as spacing between content and footer if no images are present (should the margin be applied to document container/last element instead?)
Should asterisks be used instead of img? Might wrap img in button for lightbox in the future
 */

.thumbnail-container {
	img {
		max-width: 120px;
		max-height: 120px;
		/* margin: 3px 3px; */
	}
	button {
		padding: 0;
	}
	ul.menu li {
		margin: 4px 4px;
		button {
			padding: 0px;
			border: 0px;
		}
	}
}

.thumbnail-container-middle {
	@extend .thumbnail-container;
	img,
	button {
		vertical-align: middle;
	}
	ul {
		align-items: center;
	}
}

.thumbnail-container-old {
	img {
		/* margin: 3px 3px; */
		max-width: 100%;
		height: auto;
		vertical-align: top;
	}
	button {
		padding: 0;
		margin: 3px 3px;
		max-width: 100%;
		height: auto;
		vertical-align: top;
	}
	ul.menu li {
		margin: 2px 2px;
		button {
			padding: 0px;
			border: 0px;
		}
	}
}

.thumbnail-container-old-middle {
	@extend .thumbnail-container-old;
	img,
	button {
		vertical-align: middle;
	}
	ul {
		align-items: center;
	}
}

.thumbnail-container-old-wide {
	max-width: 575px;
	display: flex;
	img {
		/* margin: 3px 3px; */
		max-width: none;
		height: auto;
		vertical-align: top;
	}
	img:not(:first-child) {
		/* margin: 3px auto; */
	}
	button {
		padding: 0px;
		margin: 0.7rem 1rem;
		max-width: 100%;
		height: auto;
		vertical-align: top;
	}
	ul.menu li button {
		padding: 0px;
		border: 0px;
		margin-right: 5em;
	}
}


/* Center all content in this class */

.center-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	text-align: center;
	margin: 0 auto;
	width: 100%;
}


/* Used for some press release pages */

.large-image-container {
	width: 60%;
	margin: auto;
	flex-shrink:1;
	min-height:1px;
}

.menu_lv1 span {
	display: block;
	max-width: 10em;
}

a.menu_lv1 {
	padding-left: 0.7rem;
}

.submenu-toggle {
	width: 25px;
}


/* Test class for reveal */

.lightbox-container {
	max-height: 90vh;
	.lightbox-text {
		max-height: 20vh;
		overflow: auto;
	}
	img {
		width: 100%;
	}
}

.press-content p {
	color: #303030;
	/*font-size: 0.8125rem;*/
	// line-height: 2;
	line-height: 1.6;
	margin-bottom: 1em;
}

table.press-table {
	tbody {
		tr,
		tr td,
		tr th {
			border: 1px solid #c6d5e1;
			color: #000000;
		}
	}
	&#table-20161207a {
		td {
			max-width: 11.375rem;
			vertical-align: baseline;
			table-layout: fixed;
		}
	}
	&#table-20170517b-1 {
		td {
			vertical-align: top;
			&:first-child {
				/* max-width: 11.875rem; 190px (16base) */
				width: 19rem;
			}
		}
	}
	&#table-20170517b-1-zh-hk {
		th {
			white-space: nowrap;
			width: auto;
		}
		tr:not(:last-child) {
			td {
				vertical-align: top;
				&:nth-child(2) {
					/* max-width: 11.875rem; 190px (16base) */
					width: 19rem;
				}
			}
		}
	}
	&#table-20170517b-2-zh-hk {
		th {
			white-space: nowrap;
			width: auto;
		}
	}
}

//For text under thumbnails in press-release
.under-image {
	margin-top: 0.5em;
}

//Class for tables in certain index pages
.generic-table,
.index-table,
.generic-table-reverse {
	tr,
	th,
	td {
		border-color: transparent;
	}
	th,
	td {
		padding: 0.5rem 0.625rem 0.625rem;
	}
	th {
		background-color: #1d8a19;
		color: white;
		text-align: left;
	}
	a {
		color: #088725;
	}
}

.generic-table,
.index-table {
	tr:nth-child(even) {
		background-color: #ecf2f6;
	}
}

.td-top-aligned {
	td {
		vertical-align: top;
	}
}

.generic-table-reverse {
	tr:nth-child(odd) {
		background-color: #ecf2f6;
	}
}

.index-table {
	td:last-of-type {
		white-space: nowrap;
	}
}

.sitemap-container,
.links-container,
.about-us-container,
.generic-container {
	.container-title {
		background-color: #1d8a19;
		color: white;
		padding: 0.5rem 0.625rem 0.625rem;
		font-weight: bold;
	}
}

.container-title.empty {
	//Should be same as p font size
	padding: 0.71875rem 0;
}

.generic-container {
	.container-body {
		padding: 1rem 0.75rem;
		background-color: #ecf2f6;
	}
}

.sitemap-container {
	.container-body {
		padding: 1rem 0.75rem;
		background-color: #ecf2f6;
		li {
			margin: 0.5rem 0;
			ul li:first-child {
				margin-top: 1rem;
			}
			ul li:last-child {
				margin-bottom: 1rem;
			}
		}
	}
}

.links-container {
	.container-body {
		border: 1px solid #d0d0d0;
		padding: 2rem 1rem;
		>ul {
			margin-left: 0;
		}
		ul.tight-points {
			li {
				padding-bottom: 0;
				padding-top: 0;
				&:first-child {
					padding-top: 0.5rem;
				}
			}
		}
		li {
			.inner-bullet-point {
				padding: 0.5rem 0;
			}
			.link-icon {
				padding-left: 0.5rem;
			}
			&:not(.inner-bullet-point) {
				list-style-type: none; //Ugly fix for bullet point (position is completely off if list-style-image is used), consider revising if possible
				background: url(../../assets/images/arrow.png) no-repeat 0 0.7em;
				padding: 0.5rem 3.5rem;
				border-bottom: 1px solid #ebebeb;
				&:first-child {
					background: url(../../assets/images/arrow.png) no-repeat 0 0.25em;
					padding-top: 0;
				}
				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}
}

.about-us-container {
	.container-body {
		border: 1px solid #d0d0d0;
	}
	ul {
		margin-top: 1.5rem; //margin-left: 2rem;
		margin-right: 1.25rem;
		list-style-type: none;
		li {
			border-bottom: 1px solid #ebebeb;
			/* Can't think of a way that can:
				-Keep some spacing between the bullet point and the text
				-Give every line the same amount of indentation
				-Align the bullet points properly
				-Extend the horizontal rule/bottom border/whatever it should be to the bullet point as well
				Not user specification though */
			background: url(../../assets/images/arrow.png) no-repeat 0 1.2em;
			padding-left: 2.5rem;
			padding-right: 2.5rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}
}

.slideshow {
	.orbit-container {
		max-height: 100%;
	}
	.orbit-previous,
	.orbit-next {
		background-color: rgba(10, 10, 10, 0.5);
	}
}

.bracket-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #628bc0;
	* {}
	p {
		text-align: center;
		flex: 1 1 auto;
		color: white;
		font-weight: bold;
		margin-bottom: 0;
	}
	img {
		padding: 0 1rem;
		max-width: 100%;
		flex: 0 0 auto;
		height: 90%;
	}
}

.media-banner {
	margin-bottom: 0;
	position: relative;
	.media-object-section:last-child:not(:nth-child(2)) {
		padding-left: 0;
	}
	.media-object-section:first-child {
		padding-right: 0;
		width: 80%;
	}
	.media-object-section:not(.bracket-container) {
		flex: 2 1 0px;
	}
	.media-object-section:not(.main-section) img {
		height: 100%;
	}
	$text-width: 31.8%;
	$img-width: 100% - $text-width;
	.img-container {
		width: $img-width;
	}
	.bracket-container {
		width: $text-width;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
	}
	@include breakpoint(small) {
		// .img-container, .bracket-container {
		// 	width: 100%;
		// }
		// .bracket-container {
		// 	position: relative;
		// }
	}
}


table.it-table {
	border: 1px solid #c6d5e1;
	tbody {
		tr,
		td,
		th {
			border: 1px solid #c6d5e1;
		}
	}
	tbody tr td:last-of-type {
		white-space: normal;
	}
	td {
		padding-top: 1rem;
		vertical-align: baseline;
	}
	p:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

textarea.lightbox-textarea {
	cursor: initial;
	background-color: initial;
}

.exit-help,
.img-counter {
	font-size: 1.4em;
}

.exit-help {
	float: left;
}

.img-counter {
	float: right;
}

//Fix for jumps when lightbox is open
body.is-reveal-open {
	overflow: visible;
}

.reveal-overlay {
	overflow-y: hidden;
}

.pre-line-text {
	white-space: pre-line;
}

.no-margin {
	margin: 0 !important;
}

.new-banner-orbit {
	.orbit-caption {
		position: absolute;
		right: 0;
		width: 33%;
		top: 0;
		background-color: #4e78b3;
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		img {
			height: 100%;
		}
		span {
			text-align: center;
		}
	}
	.orbit-next {
		right: 31.8%;
	}
	.orbit-bullets {
		position: absolute;
		bottom: 0;
		display: flex;
		right: 0;
		width: 31.8%;
		justify-content: center;
		align-items: center;
		button {
			width: 0.5rem;
			height: 0.5rem;
			margin: 0.35rem;
			.is-active {
				background-color: #d69746;
			}
		}
	}
}

.arrow-list {
	//Probably needs an image without spacing
	list-style-image: url(../../assets/images/menu/arrow.png);
}

.blog-image-container {
	max-width: 13.375rem;
	width: 50%;
	height: auto;
	margin: 0 1rem;
	margin-bottom: 1rem;
	align-items: center;
	&.left-aligned {
		float: left;
		margin-left: 0;
	}
	&.right-aligned {
		float: right;
		margin-right: 0;
	}
	&.no-max-width {
		img {
			max-width: none;
			width: 100%; //margin: 0.5rem;
		}
		margin: 0.5rem;
		max-width: none;
		width: 100%;
	}
	.container-body {
		background-color: #dce78b;
		* {
			margin: 0;
		}
	}
	img {
		width: 100%;
	}
	a {
		text-align: center;
	}
	@include breakpoint(small only) {
		&.left-aligned,
		&.right-aligned {
			width: 100%;
			max-width: none;
			float: none;
			height: auto;
			margin: 0;
			margin-bottom: 1rem;
		}
		img {
			display: block;
			margin: 0 auto;
			max-width: none;
		}
	}
}

.clear-self {
	clear: both;
}

.bg-multi-img-container {
	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	img {
		//display: inline-flex;
		//max-width: 25%;
		width: 100%;
		height: auto;
	}
}

.large-image-text {
	text-align: center;
	margin: 0 auto;
	width: 100%;
}
.m_share{
	display:table;
	padding-top:0.5rem;
	padding-left:0.55rem;
}
.skip2content{
	height:1px;
	width:1px;
	position:absolute;
	overflow:hidden;
	top:-10px;
}
.skip2content:focus {
  height: auto;
  width: auto;
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 49%;
}


@import 'components/print';