.font-changer {
	font-size: 1rem; // avoid other style cause its flickering when changing font

	a, button {
		padding: 0;
		display: inline-block; // line-height: 1.25rem;
		color: black;
		vertical-align: baseline;
		&.selected {
			color: $selected-red;
		}
	}
	.small {
		font-size: rem-calc(12);
	}
	.medium {
		font-size: rem-calc(16);
	}
	.large {
		font-size: rem-calc(20);
	}
}
